<template>
  <div class="ShareAward_wrapper">
    <el-form :model="ShareBonusDetail" :rules="ShareAwardRules" label-width="200px">
      <el-form-item label="分享最高奖励（元）" prop="maxBonus">
        <el-input v-model="ShareBonusDetail.maxBonus" placeholder="请输入最高奖励" maxlength="8"></el-input>
        <span>最高奖励可自由设置(输入0则关闭分享)</span>
      </el-form-item>
      <el-form-item label="分享最低奖励（元）" prop="minBonus">
        <el-input v-model="ShareBonusDetail.minBonus" placeholder="请输入最低奖励" maxlength="8"></el-input>
        <span>最低奖励0.3(输入0则关闭分享)</span>
      </el-form-item>
      <el-form-item label="单人单天最多可获得奖励" prop="oneDayTotalMaxBonus">
        <el-input
          v-model.float="ShareBonusDetail.oneDayTotalMaxBonus"
          placeholder="请输入单人单天最多可获得奖励"
          maxlength="8"
        ></el-input>
        <span>超过后，将不再发放奖励</span>
      </el-form-item>
      <el-form-item label="分享奖励发放上限" prop="totalUpperLimitBonus">
        <el-input
          v-model="ShareBonusDetail.totalUpperLimitBonus"
          placeholder="请输入分享奖励发放上限"
          maxlength="8"
        ></el-input>
        <span>超过后，将不再发放奖励</span>
      </el-form-item>
      <el-form-item label="导购员参与分享奖励" required>
        <el-radio-group v-model="ShareBonusDetail.kpiUserStatus">
          <el-radio label="active">可参与</el-radio>
          <el-radio label="forbidden">不可参与</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="奖励规则" required>
        <vue-ueditor-wrap v-model="ShareBonusDetail.rule" :config="ueditorConfig"></vue-ueditor-wrap>
      </el-form-item>
      <!-- <el-form-item label="成为代言人海报图" prop="poster" class="up_poster mt20" required>
        <label for="spokesman-logo" class="el-upload el-upload--picture-card">
          <input
            type="file"
            ref="spokesmanlogo"
            hidden
            id="spokesman-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage"
          />
          <img
            v-if="ShareBonusDetail.spokesmanImg"
            :src="ShareBonusDetail.spokesmanImg"
            style="width:495px;height:142px;"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </label>
        <div class="ml10 form_tips mt20">点击图片可修改，建议尺寸750*225 px，支持JPG，PNG，JPEG格式</div>
      </el-form-item>-->
      <!-- <el-form-item label="推广海报图" prop="poster" class="up_poster" required>
        <label for="brand-logo" class="el-upload el-upload--picture-card">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage2"
          />
          <img
            v-if="ShareBonusDetail.shareImg"
            :src="ShareBonusDetail.shareImg"
            style="width:142px;height:142px;"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </label>
        <span class="ml10 form_tips">建议尺寸300 * 300px，支持JPG，PNG，JPEG格式</span>
      </el-form-item>-->
      <el-form-item label="推广海报图" prop="poster" class="up_poster mt20" required>
        <div class="mt20">
          <el-button type="primary" icon="el-icon-plus" @click="getFiles">添加推广海报图</el-button>
        </div>
        <img
          v-show="!ShareBonusDetail.promotionImg"
          src="https://j.weizan.cn/zhibo/livecontent/homelive/img/templateSampleGraph.png"
          class="mt15 showImg"
          alt
        />
        <div class="mt15 showImg" v-show="ShareBonusDetail.promotionImg">
          <img
            :style="{ backgroundImage: 'url('+ShareBonusDetail.promotionImg+')'}"
            class="w100p h100p"
            src="https://j.weizan.cn/zhibo/livecontent/homelive/img/shoppingGuideTemplate.png"
          />
          <i
            @click.stop="ShareBonusDetail.promotionImg = ''"
            class="el-icon-circle-close pa cfff cp p5"
            style="font-size: 22px;color: #fff;border-radius: 0 0 0 8px;background-color: rgba(0,0,0,0.3);right: 0;"
          ></i>
        </div>
        <span class="ml10 form_tips">建议尺寸750x1334px或9：16， JPG、PNG格式，图片小于2M</span>

        <label for="brand-logo" class="el-upload el-upload--picture-card" style="display:none">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage2"
          />
          <img
            v-if="ShareBonusDetail.promotionImg"
            :src="ShareBonusDetail.promotionImg"
            style="width:142px;height:255px;"
          />
        </label>
      </el-form-item>
      <!-- <el-form-item label="分享标题" prop="title">
        <el-input
          v-model="ShareBonusDetail.title"
          placeholder="请填写标题，最多输入30个字"
          maxlength="20"
          rows="3"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="分享描述" prop="description">
        <el-input
          v-model="ShareBonusDetail.description"
          placeholder="请填写分享描述，最多输入38个字"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="submitShareBonus">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

import filterXSS from "filterXSS";
import VueUeditorWrap from "vue-ueditor-wrap";

let origin = window.location.origin;
let ueditorurl = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192.168.") ||
  window.location.hostname.includes("admin-test.youxiangzb.com")
) {
  ueditorurl = "/UEditor/";
} else {
  // ueditorurl = "https://admin.youxiangzb.com/admin/open/UEditor/";
  ueditorurl = "/UEditor/";
}

export default {
  name: "ShareAward", // 活动-营销-分享奖励设置
  components: {
    VueUeditorWrap,
  },
  props: {
    activityID: String,
  },
  data() {
    return {
      ShareAwardForm: {
        name: "",
        poster: "",
        radio: 0,
        content: "",
      },
      ShareAwardRules: {
        maxBonus: [
          { required: true, message: "请输入最高奖励", trigger: "blur" },
        ],
        minBonus: [
          { required: true, message: "请输入最低奖励", trigger: "blur" },
        ],
        oneDayTotalMaxBonus: [
          {
            required: true,
            message: "请输入单人单天最多可获得奖励",
            trigger: "blur",
          },
        ],
        totalUpperLimitBonus: [
          {
            required: true,
            message: "请输入分享奖励发放上限",
            trigger: "blur",
          },
        ],
        // title: [{ required: true, message: "请填写标题", trigger: "blur" }],
        // description: [
        //   { required: true, message: "请填写分享描述", trigger: "blur" }
        // ]
      },
      ueditorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameWidth: 700,
        initialFrameHeight: 320,
        // UEDITOR_HOME_URL: `${process.env.VUE_APP_URL}/js/ueditor1_4_3_3-utf8-net/utf8-net/`
        UEDITOR_HOME_URL: ueditorurl,
      },
      ShareBonusDetail: {
        actId: 0,
        description: "",
        kpiUserStatus: "active",
        maxBonus: "",
        minBonus: "",
        oneDayTotalMaxBonus: "",
        rule: "  ",
        spokesmanImg: "https://j.weizan.cn/youxiang/default-head-pic.png",
        shareImg: "",
        title: "",
        totalUpperLimitBonus: "",
        promotionImg: "https://j.weizan.cn/youxiang/default-poster-bg.png",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    //点击上传
    getFiles() {
      if (this.ShareBonusDetail.promotionImg) {
        this.$message.error("最多可支持添加1张海报,请先删除！");
      } else {
        this.$refs.brandlogo.click();
      }
    },
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.ShareBonusDetail.spokesmanImg = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    async uploadImage2(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 2,
        });
        this.ShareBonusDetail.promotionImg = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 分享奖励设置-查看，传入活动id。(尚未创建时返回null）
    async getShareBonus() {
      try {
        const data = await MarketingAjax.getShareBonus(this.activityID);
        if (data.data) {
          this.ShareBonusDetail = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑分享奖励设置
    async submitShareBonus() {
      try {
        this.ShareBonusDetail.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.ShareBonusDetail));
        // 验证数据
        if (this.isNullOrEmpty(act.maxBonus)) {
          this.$message.warning("最高奖励不能为空");
          return;
        }
        if (act.maxBonus != 0 && act.maxBonus < 0.3) {
          this.$message.warning("最高奖励不能小于0.3");
          return;
        }
        if (
          act.maxBonus != 0 &&
          /^\d+(\.\d{1,2})?$/.test(act.maxBonus) == false
        ) {
          this.$message.warning("请输入正确最高奖励，最多两位小数");
          return;
        }
        if (this.isNullOrEmpty(act.minBonus)) {
          this.$message.warning("最低奖励不能为空");
          return;
        }
        if (act.minBonus != 0 && act.minBonus < 0.3) {
          this.$message.warning("最低奖励不能小于0.3");
          return;
        }
        if (
          act.minBonus != 0 &&
          /^\d+(\.\d{1,2})?$/.test(act.minBonus) == false
        ) {
          this.$message.warning("请输入正确最低奖励，最多两位小数");
          return;
        }
        if (
          act.maxBonus != 0 &&
          act.minBonus != 0 &&
          act.maxBonus < act.minBonus
        ) {
          this.$message.warning("分享最高奖励不能小于分享最低奖励");
          return;
        }
        if (this.isNullOrEmpty(act.oneDayTotalMaxBonus)) {
          this.$message.warning("单人单天最多可获得奖励不能为空");
          return;
        }
        if (act.oneDayTotalMaxBonus != 0 && act.oneDayTotalMaxBonus < 0.3) {
          this.$message.warning("单人单天最多可获得奖励不能小于0.3");
          return;
        }
        if (
          act.oneDayTotalMaxBonus != 0 &&
          /^\d+(\.\d{1,2})?$/.test(act.oneDayTotalMaxBonus) == false
        ) {
          this.$message.warning(
            "请输入正确单人单天最多可获得奖励，最多两位小数"
          );
          return;
        }
        if (this.isNullOrEmpty(act.totalUpperLimitBonus)) {
          this.$message.warning("分享奖励发放上限不能为空");
          return;
        }
        if (act.totalUpperLimitBonus < 0.3) {
          this.$message.warning("分享奖励发放上限不能小于0.3");
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(act.totalUpperLimitBonus) == false) {
          this.$message.warning("请输入正确分享奖励发放上限，最多两位小数");
          return;
        }

        if (this.isNullOrEmpty(act.rule)) {
          this.$message.warning("奖励规则不能为空");
          return;
        }
        // if (!act.spokesmanImg) {
        //   this.$message.warning("请添加成为代言人海报图");
        //   return;
        // }
        if (!act.promotionImg) {
          this.$message.warning("请添加推广海报图");
          return;
        }
        // if (this.isNullOrEmpty(act.title)) {
        //   this.$message.warning("分享标题不能为空");
        //   return;
        // }
        // if (this.isNullOrEmpty(act.description)) {
        //   this.$message.warning("分享描述不能为空");
        //   return;
        // }

        console.log(act);
        await MarketingAjax.saveShareBonus(act);
        this.$message.success("编辑分享奖励设置成功");
        await this.getShareBonus();
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getShareBonus();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.ShareAward_wrapper {
  .el-upload--picture-card {
    width: 500px;
    height: 145px;
  }
  .showImg {
    width: 225px;
    height: 406px;
    margin: 20px 5px 5px 0;
    position: relative;
    .w100p {
      width: 100% !important;
    }
    .h100p {
      height: 100% !important;
    }
  }
  .showImg img:first-child {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .up_poster {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-upload--text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 178px;
        height: 178px;
      }
    }
  }
}
</style>
