var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ShareAward_wrapper" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.ShareBonusDetail,
            rules: _vm.ShareAwardRules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分享最高奖励（元）", prop: "maxBonus" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入最高奖励", maxlength: "8" },
                model: {
                  value: _vm.ShareBonusDetail.maxBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.ShareBonusDetail, "maxBonus", $$v)
                  },
                  expression: "ShareBonusDetail.maxBonus"
                }
              }),
              _c("span", [_vm._v("最高奖励可自由设置(输入0则关闭分享)")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分享最低奖励（元）", prop: "minBonus" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入最低奖励", maxlength: "8" },
                model: {
                  value: _vm.ShareBonusDetail.minBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.ShareBonusDetail, "minBonus", $$v)
                  },
                  expression: "ShareBonusDetail.minBonus"
                }
              }),
              _c("span", [_vm._v("最低奖励0.3(输入0则关闭分享)")])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "单人单天最多可获得奖励",
                prop: "oneDayTotalMaxBonus"
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入单人单天最多可获得奖励",
                  maxlength: "8"
                },
                model: {
                  value: _vm.ShareBonusDetail.oneDayTotalMaxBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.ShareBonusDetail, "oneDayTotalMaxBonus", $$v)
                  },
                  expression: "ShareBonusDetail.oneDayTotalMaxBonus"
                }
              }),
              _c("span", [_vm._v("超过后，将不再发放奖励")])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "分享奖励发放上限", prop: "totalUpperLimitBonus" }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入分享奖励发放上限",
                  maxlength: "8"
                },
                model: {
                  value: _vm.ShareBonusDetail.totalUpperLimitBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.ShareBonusDetail, "totalUpperLimitBonus", $$v)
                  },
                  expression: "ShareBonusDetail.totalUpperLimitBonus"
                }
              }),
              _c("span", [_vm._v("超过后，将不再发放奖励")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "导购员参与分享奖励", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ShareBonusDetail.kpiUserStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.ShareBonusDetail, "kpiUserStatus", $$v)
                    },
                    expression: "ShareBonusDetail.kpiUserStatus"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "active" } }, [
                    _vm._v("可参与")
                  ]),
                  _c("el-radio", { attrs: { label: "forbidden" } }, [
                    _vm._v("不可参与")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖励规则", required: "" } },
            [
              _c("vue-ueditor-wrap", {
                attrs: { config: _vm.ueditorConfig },
                model: {
                  value: _vm.ShareBonusDetail.rule,
                  callback: function($$v) {
                    _vm.$set(_vm.ShareBonusDetail, "rule", $$v)
                  },
                  expression: "ShareBonusDetail.rule"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "up_poster mt20",
              attrs: { label: "推广海报图", prop: "poster", required: "" }
            },
            [
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.getFiles }
                    },
                    [_vm._v("添加推广海报图")]
                  )
                ],
                1
              ),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.ShareBonusDetail.promotionImg,
                    expression: "!ShareBonusDetail.promotionImg"
                  }
                ],
                staticClass: "mt15 showImg",
                attrs: {
                  src:
                    "https://j.weizan.cn/zhibo/livecontent/homelive/img/templateSampleGraph.png",
                  alt: ""
                }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ShareBonusDetail.promotionImg,
                      expression: "ShareBonusDetail.promotionImg"
                    }
                  ],
                  staticClass: "mt15 showImg"
                },
                [
                  _c("img", {
                    staticClass: "w100p h100p",
                    style: {
                      backgroundImage:
                        "url(" + _vm.ShareBonusDetail.promotionImg + ")"
                    },
                    attrs: {
                      src:
                        "https://j.weizan.cn/zhibo/livecontent/homelive/img/shoppingGuideTemplate.png"
                    }
                  }),
                  _c("i", {
                    staticClass: "el-icon-circle-close pa cfff cp p5",
                    staticStyle: {
                      "font-size": "22px",
                      color: "#fff",
                      "border-radius": "0 0 0 8px",
                      "background-color": "rgba(0,0,0,0.3)",
                      right: "0"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.ShareBonusDetail.promotionImg = ""
                      }
                    }
                  })
                ]
              ),
              _c("span", { staticClass: "ml10 form_tips" }, [
                _vm._v("建议尺寸750x1334px或9：16， JPG、PNG格式，图片小于2M")
              ]),
              _c(
                "label",
                {
                  staticClass: "el-upload el-upload--picture-card",
                  staticStyle: { display: "none" },
                  attrs: { for: "brand-logo" }
                },
                [
                  _c("input", {
                    ref: "brandlogo",
                    attrs: {
                      type: "file",
                      hidden: "",
                      id: "brand-logo",
                      "data-type": "brandLogo",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadImage2 }
                  }),
                  _vm.ShareBonusDetail.promotionImg
                    ? _c("img", {
                        staticStyle: { width: "142px", height: "255px" },
                        attrs: { src: _vm.ShareBonusDetail.promotionImg }
                      })
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitShareBonus }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }